<template>
    <b-row>
        <b-col md="6">
            <comments/>
        </b-col>
        <b-col md="12">
            <b-card title="Kick start your project 🚀">
                <b-card-text>All the best for your new project.</b-card-text>
                <b-card-text>
                    Please make sure to read our
                    <b-link
                        href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
                        target="_blank"
                    >
                        Template Documentation
                    </b-link>
                    to understand where to go from here and how to use our template.
                </b-card-text>
            </b-card>

        </b-col>
    </b-row>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import Comments from "@/views/dashboard/Comments";
export default {
    components: {
        BCard,
        BCardText,
        BLink,
        Comments
    },
}
</script>

<style>

</style>
