<template>
    <b-card :title="$t('comments')">
        <b-overlay :show="status === 'LOADING'">
            <div v-for="comment in comments" :key="comment.id" class="mb-1 border-bottom">
                <div class="d-flex justify-content-between">
                    <div>{{ comment.name ? comment.name : comment.user.name }}</div>
                    <b-badge :variant="statusColor(comment.status)">
                        {{ statusText(comment.status) }}
                    </b-badge>
                </div>
                <div>{{ comment.email ? comment.email : comment.user.email }}</div>
                <div>
                    {{ $stripTags(comment.comment).substr(0, 150) }}
                </div>
            </div>
        </b-overlay>
    </b-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CommentStatus from "@/constans/CommentStatus";

export default {
    name: "Comments",

    computed: {
        ...mapGetters('comment', [
            'comments', 'status'
        ])
    },

    created() {
        this.fetchComments({ per_page: 6 })
    },

    methods: {
        ...mapActions({
            fetchComments: 'comment/fetch',
        }),
        statusColor(value) {
            if (value === CommentStatus.PENDING) return 'warning'
            if (value === CommentStatus.APPROVED) return 'success'
            if (value === CommentStatus.UNAPPROVED) return 'danger'

        },
        statusText(value) {
            if (value === CommentStatus.PENDING) return this.$t('pending')
            if (value === CommentStatus.APPROVED) return this.$t('approved')
            if (value === CommentStatus.UNAPPROVED) return this.$t('unapproved')
        }
    }
}
</script>
